import React, { Component } from 'react';  
import { Link,Redirect } from 'react-router-dom';
import axios from 'axios';  

class Login extends Component { 

    constructor(props) {
        super(props); 
        this.state = {
          email: '',
          password: ''
        };
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;  
        axios.post(process.env.REACT_APP_API_URL + 'checkvaliduser', { email: email, password: password })
          .then((result) => { 
            if(result.data != null && result.data != undefined){   
                localStorage.setItem("usersession",result.data._id);
                this.props.parentContext.props.history.push("/");
            }
            else{
                alert("Enter valid user name and password");
            }
        }); 
    }

    render() {
        return (
            <div className="App">
            <div className="appBody">
                <div className="panel"> 
                <form onSubmit={this.onSubmit} className="form">
                <h2 align="center" className="modal-title"> PLEASE LOGIN</h2>
                <div className="form-group"> 
                    <label className="control-label">Email</label>
                    <input type="text" name="email" placeholder="Email Address" required="required" className="form-control" onChange={this.onChange}/> 
                </div>
                <div className="form-group"> 
                        <label className="control-label">Password</label>
                        <input type="password" name="password"  placeholder="Password"  required="required" className="form-control" onChange={this.onChange}/> 
                </div>
                <button type="submit" className="btn btn-success btn-block btn-lg">Login</button>
                </form>
                </div> 
                <div className="text-center small">Don't have an account? <Link className="green" to={`/signup`}>Register</Link></div>
            </div> 
            </div> 
        );
    }
}

export default Login;