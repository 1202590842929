import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import axios from 'axios';   
 
class UsbDevices extends Component {  
    constructor(props) {  
        super(props); 
        this.state = {
            usbDevices:[]
        }
    }

    componentDidMount() {
        this.fetchAllData();
    }

    fetchAllData(){
        axios.get(process.env.REACT_APP_API_URL+'usb-devices')
        .then(res => {  
            this.setState({ usbDevices: res.data });    
        });
    }

    render() {  
        return ( 
            <div className="row container">
                   <table className="table">
                        <thead>
                            <tr>
                                <th>LocationId</th>
                                <th>VendorId</th>
                                <th>ProductId</th>
                                <th>DeviceName</th>
                                <th>SerialNumber</th>
                                <th>DeviceAddress</th> 
                                <th>CreatedOn</th> 
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.usbDevices.map(usbDevice =>
                            <tr key={usbDevice._id}>
                                <td>{usbDevice.locationId}</td>
                                <td>{usbDevice.vendorId}</td>
                                <td>{usbDevice.productId}</td>
                                <td>{usbDevice.deviceName}</td>
                                <td>{usbDevice.serialNumber}</td>
                                <td>{usbDevice.deviceAddress}</td> 
                                <td>{usbDevice.Created_date}</td> 
                            </tr> 
                        )}
                        </tbody>
                    </table>
            </div>
        )
    }
}

export default UsbDevices;

