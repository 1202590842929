import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import axios from 'axios';  

var self;

class ActivateUsers extends Component {  

    constructor() {  
        super();
        this.state = {
            activeUsers: []
        }
        self = this;
    }

    componentDidMount() {
        self.fetchAllData();
    }

    fetchAllData(){
        axios.get(process.env.REACT_APP_API_URL+'activeusers')
        .then(res => {   
            self.setState({ activeUsers: res.data});    
        });
      } 

    render() {
        return (
            <div className="row container"  style={{padding:'3em'}}> 
                <div className="col-md-12 float-left">
                    <div className="col-md-6 float-left">
                        <h2 className="adminPageTitle">ADMINISTRATION PANEL</h2>
                        <div className="pageContent">
                            <h2 className="adminPageTitle">CONNECT PAYMENT GATEWAY :</h2> 
                            <div className="col-md-12 float-left">
                                <div className="col-md-5 float-left">
                                    <div className="card text-center">
                                        <h4>Paypal</h4>
                                        <Link  className="btn btn-primary" style={{color:'white'}} to={`/paypal-gateway-configuration`}>CONNECT</Link>  
                                    </div>
                                </div>
                                <div className="col-md-5 float-left">
                                    <div className="card text-center" >
                                        <h4>Stripe</h4>
                                        <Link  className="btn btn-primary" style={{color:'white'}} to={`/stripe-gateway-configuration`}>CONNECT</Link>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 float-left"> 
                        <h2 className="adminPageTitle" align="right"><Link  style={{color:'white'}} to={`/`}>DEVICE MANAGEMENT</Link></h2>
                        <div className="pageContent table-responsive"> 
                            <table className="table userTable">
                                {this.state.activeUsers.map(activateUser => 
                                    <tr key={activateUser._id}>
                                        <td>{activateUser.username}</td>
                                        <td>
                                            {
                                                activateUser.activateStatus ? <button disabled className="btn btn-success">ACTIVE</button> : <button disabled className="btn btn-danger">FAILED</button>
                                            }
                                        </td>
                                        <td>
                                            {
                                                activateUser.activateStatus ? <span></span> : <button className="btn btn-primary">SEND REMINDER</button>
                                            }
                                        </td>
                                    </tr>
                                )} 
                            </table> 
                        </div> 
                    </div>
                </div>
            </div> 
        );
    }
}

export default ActivateUsers