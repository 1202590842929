import React, { Component } from 'react';    
import { Link } from 'react-router-dom'; 
import logo from '../logo.png'  
import MultiSelect from './multiSelect'  
import axios from 'axios';  
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {CSVLink} from 'react-csv';  
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BarcodeScanner from "simple-barcode-scanner";


var $ = require ('jquery'); 


var subProps;
var self;
// var barCodeData;
const scanner = BarcodeScanner();
const phyOptions = [
    {
        text:"Yes",
        class: "onSelect"
    },
    {
        text:"No",
        class: "offSelect"
    }
]

const ssdHddOptions = [
    {
        text:"HDD",
        class: "onSelect"
    },
    {
        text:"SSD",
        class: "offSelect"
    }
] 

scanner.on(data =>{
    self.setState({
        SerialNumber: data
    });
    document.getElementById("SerialNumber").value = data;
    self.addNewRow();
}); 

// const csvHeaders =['Date Time','Device','User','Serial Number','Physical Destruction?','Wireless Initials','SSD OR HDD','Comments']
const csvHeaders =['Date Time','Device','User','Serial Number','Wireless Initials','Comments']

class Home extends Component {  
    

    constructor(props) {  
        super(props); 
        subProps =props;  
        this.state = {
            UserDeviceId:null,
            Date_Time: new Date(), 
            Device: 'T-1',
            modal: false ,
            searchText: '',
            User:'',
            UserForm:'',
            ActiveUser:'',
            SerialNumber: '',
            Physical_Destruction: 'Yes', 
            SSD_HDD: 'SSD', 
            CreatedBy: localStorage.getItem("usersession"),
            Comments: '', 
            Wireless_Initials: '',
            userDevices:[],
            csvDatas:[],
            hiddenExtraRows:'hidden'
        }; 
        self = this;  
    }
 
    logout() {
        localStorage.clear();  
        subProps.parentContext.props.history.push("/"); 
    }

    onMultiSelect(text,fieldName){ 
        const state = self.state; 
        state[fieldName] = text;
        self.setState(state);
    }

    componentDidMount() {
        this.fetchAllData();
      }

    fetchAllData(){
        axios.get(process.env.REACT_APP_API_URL+'userdevices/' + self.state.CreatedBy)
        .then(res => { 
            var csvDatas = [];
            res.data.map(userDevice => {
                csvDatas.push({
                                'Date Time' :userDevice.Date_Time,
                                'Device' :userDevice.Device,
                                'User' :userDevice.User,
                                'Serial Number' :userDevice.SerialNumber,
                                // 'Physical Destruction?' :userDevice.Physical_Destruction,
                                'Wireless Initials' :userDevice.Wireless_Initials,
                                // 'SSD OR HDD' :userDevice.SSD_HDD,
                                'Comments' :userDevice.Comments
                });
            });
            self.setState({ userDevices: res.data,csvDatas: csvDatas });    
        });
      }
     
    addNewRow(){  
        self.setState({
            hiddenExtraRows: ''
        }); 
    }

    onChange = (e) => {
        console.log(e.target.name);
        const state = self.state;
        state[e.target.name] = e.target.value;
        self.setState(state);
    }

    handleChange(date) {
        self.setState({
            Date_Time: date
        });
      }

      activeUser(){
        if(self.state.UserForm != ""){
            axios.post(process.env.REACT_APP_API_URL+'activeusers', {username:self.state.UserForm,activateStatus:true})
            .then((result) => {   
                self.setState({ 
                    ActiveUser: result.data.username,
                    User: result.data.username
                });
            }); 
        }
        else{
            alert("Enter user email address");
        }
      }

      saveDeviceData(){
        axios.post(process.env.REACT_APP_API_URL+'userdevices', self.state)
        .then((result) => { 
            self.setState({
                hiddenExtraRows: 'hidden'
            });
            self.fetchAllData(); 
        }); 
      }

      hideForm(){
        self.setState({
            hiddenExtraRows: 'hidden'
        });
      }

      printTableData(){  
        // var tableData =  document.getElementById('innerTable')
        // var row = tableData.getElementsByTagName('tbody')[0];
        // var firstChild = row.firstChild;
        // row.removeChild(row.firstChild);
        // var secondChild = row.firstChild;
        // row.removeChild(row.firstChild);
        var htmlString ="";

        htmlString +=  "<table class='table table-bordered'> <thead>"+
                        " <tr>"+
                        "<th>DATE AND TIME</th>"+
                        "<th>DEVICE</th>"+
                        "<th>USER</th>"+
                        "    <th>SERIAL NUMBER</th>"+
                        // "   <th>PHYSICAL DESTRUCTION? Y/N</th>"+
                        "   <th>WITNESS INITIALS</th>"+
                        // "   <th>SSD OR HDD?</th>"+
                        "   <th>COMMENTS</th>"+
                        "</tr>"+
                        "</thead>"+
                        "<tbody>" +
                        self.GetPrintTableBody() +
                        "</tbody>" +
                        "<table>"; 
 
        var mywindow = window.open('', 'PRINT', 'height=800,width=1024'); 
        mywindow.document.write('<html><head><title>Proton Data Security</title>'); 
        mywindow.document.write('</head><body >'); 
        mywindow.document.write(htmlString);
        mywindow.document.write('</body></html>');  
        mywindow.document.close();  
        mywindow.focus();  
        mywindow.print();
        mywindow.close();  
      }

      GetPrintTableBody(){ 
          var htmlString = "";
          self.state.userDevices.forEach(element => {
                   htmlString += " <tr>"+
                            "<td>"+element.Date_Time+"</td>"+
                            "<td  align='center'>"+element.Device+"</td>"+
                            "<td  align='center'>"+element.User+"</td>"+
                            "    <td  align='center'>"+element.SerialNumber+"</td>"+
                            // "   <td align='center'>"+element.Physical_Destruction+"</td>"+
                            "   <td  align='center'>"+element.Wireless_Initials+"</td>"+
                            // "   <td  align='center'>"+element.SSD_HDD+"</td>"+
                            "   <td  align='center'>"+element.Comments+"</td>"+
                            "</tr>";  
          });
          return htmlString;
      }

      toggle() {
        self.setState({
          modal: !self.state.modal
        });
      } 

      handleSubmit(event) {
        event.preventDefault(); 
        if(self.state.searchText === ""){
            alert("Search text is required.");
        }
        else{
            axios.get(process.env.REACT_APP_API_URL+'device-search/'+self.state.searchText).then(res => { 
                var csvDatas = [];
                res.data.map(userDevice => {
                    csvDatas.push({
                                    'Date Time' :userDevice.Date_Time,
                                    'Device' :userDevice.Device,
                                    'User' :userDevice.User,
                                    'Serial Number' :userDevice.SerialNumber,
                                    // 'Physical Destruction?' :userDevice.Physical_Destruction,
                                    'Wireless Initials' :userDevice.Wireless_Initials,
                                    // 'SSD OR HDD' :userDevice.SSD_HDD,
                                    'Comments' :userDevice.Comments
                    });
                });
                self.setState({ userDevices: res.data,csvDatas: csvDatas ,modal: !self.state.modal });  
            }); 
        } 
      }

    render() {  
        return ( 
            <div className="row container"> 
                <Modal backdrop="static" isOpen={this.state.modal}>
                    <form onSubmit={this.handleSubmit}>
                    <ModalHeader>Search Device</ModalHeader>
                    <ModalBody> 
                            <div className="form-group"> 
                                <input type="text" placeholder="Enter search text" name="searchText" value={this.state.searchText} onChange={this.onChange} className="form-control" />
                            </div> 
                    </ModalBody>
                    <ModalFooter>
                        <input type="submit" value="Submit" color="primary" className="btn btn-success" />
                        <Button color="danger" onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                <div className="col-md-12 float-left">
                    <div className="col-md-8 float-left">
                        <h2 className="adminPageTitle">ADMINISTRATION PANEL</h2> 
                    </div>
                    <div className="col-md-4 float-left" style={{textAlign:'right'}}>
                                         
                        <h2 className="adminPageTitle"> <Link  style={{color:'white'}} to={`/admin-panel`}>ADMIN HOME</Link></h2> 
                    </div>
                </div>
                <div className="col-md-12 float-left">  
                    <div className="col-md-9  float-left table-responsive">
                        <table className="homeTable table">
                            <thead>
                                <tr>
                                    <th>DATE AND TIME</th>
                                    <th>DEVICE</th>
                                    <th>USER</th>
                                    <th>SERIAL NUMBER</th>
                                    {/* <th>PHYSICAL DESTRUCTION? Y/N</th> */}
                                    <th>WITNESS INITIALS</th>
                                    {/* <th>SSD OR HDD?</th> */}
                                    <th>COMMENTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={this.state.hiddenExtraRows}>
                                    <td>
                                        {/* <input type="date" onChange={this.onChange} name="Date_Time"></input> */}
                                        <DatePicker 
                                            selected={this.state.Date_Time}
                                            onChange={this.handleChange}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                        />
                                    </td>
                                    <td>
                                        <select  onChange={this.onChange} name="Device" value={this.state.Device}>
                                            <option value="T-1">T-1</option>
                                            {/* <option value="T-1.5">T-1.5</option>
                                            <option value="T-4">T-4</option>
                                            <option value="T-5">T-5</option>
                                            <option value="wand">wand</option>  */}
                                        </select>
                                    </td>
                                    <td>
                                        <input type="email"  onChange={this.onChange} value={this.state.User} name="User"></input> 
                                    </td>
                                    <td>
                                        <input type="text"  onChange={this.onChange} id="SerialNumber" name="SerialNumber"></input>  
                                    </td>
                                    {/* <td> 
                                        <MultiSelect options={phyOptions} fieldName="Physical_Destruction" value={this.state.Physical_Destruction} onSelect={this.onMultiSelect}></MultiSelect>
                                    </td> */}
                                    <td>
                                        <input type="text" onChange={this.onChange} name="Wireless_Initials"></input>   
                                    </td>
                                    {/* <td> 
                                        <MultiSelect options={ssdHddOptions} fieldName="SSD_HDD" value={this.state.SSD_HDD} onSelect={this.onMultiSelect}></MultiSelect> 
                                    </td> */}
                                    <td>
                                        <textarea onChange={this.onChange} name="Comments"></textarea>
                                    </td>
                                </tr> 
                                <tr  className={this.state.hiddenExtraRows}>
                                    <td align="right" colSpan={8}>
                                        <button type="button" onClick={this.hideForm} className="btn btn-danger">CANCEL</button> 
                                        <button type="button" onClick={this.saveDeviceData} className="btn btn-success">SAVE</button>  
                                    </td>
                                </tr>
                                {this.state.userDevices.map(userDevice =>
                                    <tr>
                                    <td>
                                        <DatePicker
                                            selected={userDevice.Date_Time}
                                            onChange={this.handleChange}
                                            disabled={true}
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                        />
                                    </td>
                                    <td>
                                        <select  disabled onChange={this.onChange} value={userDevice.Device} name="Device">
                                            <option value="T-1">T-1</option>
                                            <option value="T-1.5">T-1.5</option>
                                            <option value="T-4">T-4</option>
                                            <option value="T-5">T-5</option>
                                            <option value="wand">wand</option> 
                                        </select>
                                    </td>
                                    <td>
                                        <input disabled type="email" value={userDevice.User}  onChange={this.onChange} name="User"></input> 
                                    </td>
                                    <td>
                                        <input disabled type="text"  value={userDevice.SerialNumber}  onChange={this.onChange} name="SerialNumber"></input>  
                                    </td>
                                    {/* <td> 
                                        <MultiSelect options={phyOptions} disabled={true}  value={userDevice.Physical_Destruction}   fieldName="Physical_Destruction" onSelect={this.onMultiSelect}></MultiSelect>
                                    </td> */}
                                    <td>
                                        <input disabled type="text" onChange={this.onChange}  value={userDevice.Wireless_Initials} name="Wireless_Initials"></input>   
                                    </td>
                                    {/* <td> 
                                        <MultiSelect options={ssdHddOptions} disabled={true} fieldName="SSD_HDD"  value={userDevice.SSD_HDD} onSelect={this.onMultiSelect}></MultiSelect> 
                                    </td> */}
                                    <td>
                                        <textarea disabled onChange={this.onChange}  value={userDevice.Comments}  name="Comments"></textarea>
                                    </td>
                                </tr> 
                                )}
                          </tbody> 
                        </table>
                    </div>
                    <div className="col-md-3  float-left">
                        <div align="center" style={{marginBottom:"2em"}}>
                            <img className='img-responsive' style={{height:"121px"}} src={logo}></img>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12 float-left">
                                <div className="col-md-5  float-left" style={{paddingLeft:'0px'}} align="center">  
                                    <div className="grayBlock">
                                    <h3 style={{verticalAlign:'middle',marginBottom:'0',fontWeight:'bold',padding:'0.45em',fontSize:'1.2em'}}>User ID</h3>    
                                    </div> 
                                </div>
                                <div className="col-md-7  float-left pr0">
                                    <div className="grayBlock">
                                        <input type="email" onChange={this.onChange} name="UserForm" className="form-control"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <button type="button" onClick={this.activeUser} className="btn btn-success btn-block btn-lg">ACTIVATE USER!</button> 
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <div className="grayBlock">
                                <h3 style={{verticalAlign:'middle',marginBottom:'0',fontWeight:'bold',padding:'0.45em',fontSize:'1.2em'}}>Currently active user  : {this.state.ActiveUser}</h3>    
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12 float-left">
                                <div className="col-md-5  float-left"  style={{paddingLeft:'0px'}}  align="center"> 
                                    <div className="grayBlock">
                                        <h3 style={{verticalAlign:'middle',marginBottom:'0',fontWeight:'bold',padding:'0.4em',fontSize:'1.2em'}}>Device</h3>    
                                        </div> 
                                    </div>
                                    <div className="col-md-7  float-left pr0">
                                        <div className="grayBlock">
                                        <select disabled  className="form-control" onChange={this.onChange} value={this.state.Device} name="Device">
                                            <option value="T-1">T-1</option>
                                            {/* <option value="T-1.5">T-1.5</option>
                                            <option value="T-4">T-4</option>
                                            <option value="T-5">T-5</option>
                                            <option value="wand">wand</option>  */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <div className="grayBlock" style={{padding:'1em'}}>
                                    <h3 style={{verticalAlign:'middle',marginBottom:'0',fontWeight:'bold'}}>NUMBER OF RECORDS : {this.state.userDevices.length}</h3>    
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <div className="grayBlock" style={{padding:'1em'}}>
                                    <h4 style={{verticalAlign:'middle',marginBottom:'0',fontWeight:'bold'}}>TIME OF LAST EDIT : 6</h4>    
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <CSVLink headers={csvHeaders} filename='MyReport_Proton_Data_Security.csv' data={this.state.csvDatas} className="btn btn-success btn-block btn-lg">SAVE AS CSV</CSVLink>
                                {/* <button type="button" className="btn btn-success btn-block btn-lg">SAVE AS CSV</button>  */}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <button type="button" onClick={this.printTableData} className="btn btn-success btn-block btn-lg">PRINT</button> 
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <button type="button" onClick={this.toggle} className="btn btn-success btn-block btn-lg">SEARCH</button> 
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <button type="button" onClick={this.fetchAllData} className="btn btn-success btn-block btn-lg">REFRESH DATA</button> 
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <button type="button" onClick={this.addNewRow} className="btn btn-primary btn-block btn-lg">ADD NEW ROW!</button> 
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-12">
                                <button type="button" className="btn btn-danger btn-block btn-lg"  onClick={this.logout}>LOG OUT</button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}

export default Home