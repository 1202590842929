import React, { Component } from 'react';  
import { Link } from 'react-router-dom'; 
import axios from 'axios';   

class PaypalPaymentGateWayConfig extends Component {  
   
    constructor() {  
        super();  
        this.state = {
            paypal_id: '',
            paypal:{
                enablePaypal: false,
                title:'',
                description:'',
                paypal_email:'',
                enablePaypalSanbox: false,
                enableDebugLog: false,
                enableIPNEmail: false,
                receiver_email:'',
                paypal_token:'',
                invoice_prefix:'',
                send_address: false,
                enable_address_override: false,
                payment_action:'',
                page_style:'',
                image_url:'',
                live_api_name:'',
                live_api_password:'',
                live_api_sign:''
            } 
        }; 
    }

    onChange = (e) => {
        const state = this.state.paypal;
        if(e.target.type == "checkbox")
            state[e.target.name] = e.target.checked;
        else
            state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {  
        e.preventDefault();
        console.log(this.state.paypal._id);
        if(this.state.paypal_id == ''){
            axios.post(process.env.REACT_APP_API_URL+'paypal-config/',this.state.paypal)
            .then((result) => {
              //  this.props.history.push("/"); 
            });
        }
        else{
            axios.put(process.env.REACT_APP_API_URL+'paypal-config/'+this.state.paypal_id, this.state.paypal)
            .then((result) => {
              //  this.props.history.push("/"); 
            });
        }
    }

    componentDidMount() {  
        axios.get(process.env.REACT_APP_API_URL+'paypal-config/')
          .then(res => { 
              if(res.data != null && res.data != undefined){
                this.setState({ paypal: res.data });  
                this.setState({ paypal_id: res.data._id });  
              }
        }); 
    }

    render() {
        return (
            <div className="row container" style={{marginLeft:0,paddingBottom:15}}>
                <div className="col-md-12 float-left">
                    <div className="col-md-8 float-left">
                        <h2 className="adminPageTitle">ADMINISTRATION PANEL - PAYPAL CONFIGURATION</h2> 
                    </div>
                    <div className="col-md-4 float-left" style={{textAlign:'right'}}>
                                         
                        <h2 className="adminPageTitle"> <Link  style={{color:'white'}} to={`/admin-panel`}>ADMIN HOME</Link></h2> 
                    </div>
                </div>
                <div className="col-md-12 float-left" style={{marginTop:'1em',backgroundColor:'#f3ca3e',padding:'2em',color:'black'}}> 
                    <form className="form-horizontal" onSubmit={this.onSubmit}>
                    <div className="form-group row">
                            <div className="col-md-12 float-left">
                            <div className="col-md-12 float-left">
                        <h4>Paypal</h4>
                        </div> 
                             </div>
                            </div> 
                        <div className="form-group row">
                            <div className="gray col-md-12 float-left">
                            <div className="col-md-12 float-left">
                            Paypal standard redirects customers to Paypal to enter their payment information
                             </div>
                             </div>
                            </div> 
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Enable/Disable</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="checkbox" name="enablePaypal"  checked={this.state.paypal.enablePaypal}  onChange={this.onChange}></input> Enable Paypal Standard</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Title</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="title"  value={this.state.paypal.title}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Description</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="description"  value={this.state.paypal.description}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Paypal email</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="paypal_email"  value={this.state.paypal.paypal_email}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-12 float-left">Advance Options</label> 
                            </div>
                        </div> 
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Paypal Sandbox</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="checkbox" name="enablePaypalSanbox"  checked={this.state.paypal.enablePaypalSanbox}  onChange={this.onChange}></input> Enable Paypal sandbox</label>
                                    <br></br>
                                    <div className="gray" style={{fontStyle:"italic"}}>PayPal sandbox can be used to test payments. Sign up for a developer account.</div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Debug log </label>
                                <div className="col-md-6 float-left">
                                    <label><input type="checkbox" name="enableDebugLog"  checked={this.state.paypal.enableDebugLog}  onChange={this.onChange}></input> Enable logging</label>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">IPN Email Notifications  </label>
                                <div className="col-md-6 float-left">
                                    <label><input type="checkbox" name="enableIPNEmail"  checked={this.state.paypal.enableIPNEmail}  onChange={this.onChange}></input> Enable IPN Email Notifications </label>
                                    <div className="gray" style={{fontStyle:"italic"}}>
                                    Sandbox notification when an IPN is received from PayPal including refunds, chargebacks and cancallations.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Receiver email</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="receiver_email"  value={this.state.paypal.receiver_email}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">PayPal identity token</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="paypal_token"  value={this.state.paypal.paypal_token}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Invoice prefix</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="invoice_prefix"  value={this.state.paypal.invoice_prefix}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Shipping details  </label>
                                <div className="col-md-10 float-left">
                                    <label><input type="checkbox" name="send_address"  checked={this.state.paypal.send_address}  onChange={this.onChange}></input> Send shipping details to Paypal instead of billing. </label>
                                    <div className="gray" style={{fontStyle:"italic"}}>
                                    Paypal allows us to send one address. if you are using PayPal for shipping labels you may prefer to send the shipping address rather than billing. Turning this option off may prevent PayPal Seller protection from applying.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Address override </label>
                                <div className="col-md-10 float-left">
                                    <label><input type="checkbox" name="enable_address_override"  checked={this.state.paypal.enable_address_override}  onChange={this.onChange}></input> Enable "address override" to prevent address information from being changed. </label>
                                    <div className="gray" style={{fontStyle:"italic"}}>
                                    Paypal verifies addresses therefore this sending can cause errors (we recommended keeping if disabled).
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Payment action</label>
                                <div className="col-md-6 float-left">
                                    <select name="payment_action"  value={this.state.paypal.payment_action}  onChange={this.onChange}>
                                        <option value="Capture">Capture</option> 
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Page style</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="page_style"  value={this.state.paypal.page_style}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Image url</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="image_url"  value={this.state.paypal.image_url}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>  
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-12 float-left">API Credentials</label> 
                                <br></br>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="gray col-md-12 float-left">
                                <div className="col-md-12 float-left">
                                    Enter your PayPal API credentials to process refunds via Paypal, Learn how to access your PayPal API Credentials.
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Live API username</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="live_api_name"  value={this.state.paypal.live_api_name}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Live API password</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="live_api_password"  value={this.state.paypal.live_api_password}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12 float-left">
                                <label className="control-label col-md-2 float-left">Live API singnature</label>
                                <div className="col-md-6 float-left">
                                    <label><input type="text" name="live_api_sign"  value={this.state.paypal.live_api_sign}  onChange={this.onChange}></input></label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default PaypalPaymentGateWayConfig
