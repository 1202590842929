import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; 
import './index.css';
import App from './App';
import ActivateUsers from './components/activateusers'; 

import serviceWorker from './serviceWorker'; 
import Login from './components/login';
import Register from './components/register'; 
import SignUp from './components/signup';  
import SignUpDone from './components/signupdone';
import Home from './components/home';   
import UsbDevices from './components/usbdevices';    
import StripePaymentGateWayConfig from './components/stripepaymentgetway-config';   
import PaypalPaymentGateWayConfig from './components/paypalpaymentgetway-config';   

require('dotenv').config();

ReactDOM.render(
    <Router>
        <div>
          <Route exact path='/' component={App} /> 
          <Route path='/admin-panel' component={ActivateUsers} /> 
          <Route path='/login' component={Login} />
          <Route path='/home' component={Home} />
          {/* <Route path='/register' component={Register} /> */}
          <Route path='/register/:id' component={Register} />
          <Route path='/signup' component={SignUp} />
          <Route path='/usbdevices' component={UsbDevices} />
          <Route path='/signupdone' component={SignUpDone} />
          <Route path='/signupsuccess/:id' component={SignUpDone} />
          <Route path='/stripe-gateway-configuration' component={StripePaymentGateWayConfig} />
          <Route path='/paypal-gateway-configuration' component={PaypalPaymentGateWayConfig} />
        </div>
    </Router>,
    document.getElementById('root')
  );
  serviceWorker();
