import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import logo from '../reglogo.png'
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';   

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            paymentDetail:null
        }; 
    }

    componentDidMount() {   
        if(this.props.match.params.id != undefined){
            axios.get(process.env.REACT_APP_API_URL+'users/'+this.props.match.params.id)
            .then(res => { 
              this.setState({ user: res.data }); 
          }); 
        } 
    } 

    onToken = (token, addresses) => { 
        axios.get(process.env.REACT_APP_API_URL+'payment-process/'+token.id)
            .then(res => {  
               this.setState({ paymentDetail: res.data }); 
                var userData = this.state.user;
                userData.address_line1= this.state.paymentDetail.source.address_line1
                userData.city=this.state.paymentDetail.source.address_city
                userData.country=this.state.paymentDetail.source.address_country
                userData.state= this.state.paymentDetail.source.address_state
                userData.pincode= this.state.paymentDetail.source.address_zip
                userData.strp_txn_id= this.state.paymentDetail.balance_transaction
                userData.strp_charge_id= this.state.paymentDetail.id
                this.setState({ user: userData }); 

                axios.put(process.env.REACT_APP_API_URL+'users/'+this.props.match.params.id, this.state.user)
                .then((result) => {
                    localStorage.setItem("usersession",this.props.match.params.id);
                    this.props.history.push("/"); 
                });
            }); 
    }; 

   

    render() {
        return (
          <div className="App">
            <div className="appBody"> 
            <img className='img-responsive' src={logo} style={{marginBottom:'1em'}}></img> 
            <h3 className="pageTitle">PROTON DATA DEFENDER RECORD GENERATION SERVICE</h3>
            <div className="panel" align="center"  style={{width:'300px'}}>
                <h3 className="h3 mg1">ONE SIZE FITS ALL</h3>
                <div className="price-detail">
                    <span className="price">$49</span>
                    <span className="priceper">PER COMPANY/ANNUALY</span>
                </div>
                <div className="description">
                    ONE SHARED LOGIN THEN AS <br />
                    MANY USERS AS YOU WANT <br />
                    TO HAVE TO KEEP YOUR <br />RECORDS
                </div>
                <br></br>
                <br></br>
               {/* {
                   this.state.paymentDetail != null ? 
                   <div className="description">
                        <div> <b>Charge Id :</b> {this.state.paymentDetail.id}</div>
                        <div> <b>Transcation Id :</b> {this.state.paymentDetail.balance_transaction}</div>
                        <div> <b>Name :</b> {this.state.paymentDetail.source.name}</div>
                        <div> <b>Address line 1:</b> {this.state.paymentDetail.source.address_line1}</div>
                        <div> <b>City:</b> {this.state.paymentDetail.source.address_city}</div>
                        <div> <b>Pincode:</b> {this.state.paymentDetail.source.address_zip}</div> 
                        <div> <b>Country :</b> {this.state.paymentDetail.source.address_country}</div>
                        <div> <b>Card last 4 digits :</b> {this.state.paymentDetail.source.last4}</div>
                        <div> <b>Status :</b> {this.state.paymentDetail.status}</div>
                   </div> : ""
               } */}

                <hr></hr>
                <StripeCheckout
                    amount={4900}
                    billingAddress   
                    email={this.state.user.email}
                    image={logo}
                    locale="auto"
                    name="Proton Data Security"
                    stripeKey="pk_test_ZnF8AZMYJU1WHYqvnzhPdDIT"
                    token={this.onToken}
                    zipCode
                    panelLabel="Pay {{amount}}"
                />
                {/* <Link  className="btn btn-success btn-block btn-lg" style={{color:'white'}} to={`/signup`}>SIGN UP</Link>  */}
            </div>
            </div>
          </div>
        );
    }
}

export default Register;

