import React, { Component } from 'react';  

class MultiSelect extends Component { 
    constructor(props) {
        super(props);    
        this.state = {
          options: props.options,
          value:props.value,  
          fieldName: props.fieldName,
          disabled: props.disabled | false
        }; 
    } 

    onSelect(obj,option){ 
        this.setState({value: option.text});
        this.props.onSelect(option.text,this.state.fieldName);
    }

    render() {
        return (  
            <div>
                {
                   this.state.options.map(option =>
                        <button disabled={this.state.disabled} onClick={(e) => this.onSelect(e,option)} className={this.state.value == option.text ? "multiSelectButton " + option.class : "multiSelectButton"} key={option.text}>{option.text}</button>
                    )
                }
            </div> 
        );
    }
}

export default MultiSelect